
export default [
  {
    path: "packages",
    name: "packages",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () => import("../../views/PosSubscriptionsManagement/SubscriptionsManagement/packages.vue"),
  },

]