<template>
  <div :id="options.id" :style="{ width: options.width, height: options.height }"></div>
</template>
<script>
// import store from "@/state/store";
import $ from "jquery";
export default {

  props: {
    options: Object,
    control: Boolean
  },



  data() {

    return {
      mapOptions: {}
    }
  },
  watch: {
    mapOptions() {
      console.log('changed')


    },

    stateReal(newval) {

      console.log('state us', newval)
    }
  },
  mounted() {
    console.log(this.options);
    var lat = this.options.center.lat;
    var lng = this.options.center.lng;
    var L = window.L;
    this.mapOptions = this.options.mapOptions
    // Initialize the map
    var options = {};
    if (
      // eslint-disable-next-line no-unused-vars
      // this.options.hasOwnProperty("mapOptions")
      this.options.mapOptions
    )
      options = this.options.mapOptions;
    var map = L.map(this.options.id, options).setView([lat, lng], this.options.initalZoom ? this.options.initalZoom : 14);
    if (!this.control) {

      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      // if (map.tap) map.tap.disable();
      // document.getElementById('map').style.cursor = 'default';

    }
    else {

      map.dragging.enable();
      map.touchZoom.enable();
      map.doubleClickZoom.enable();
      map.scrollWheelZoom.enable();
      map.boxZoom.enable();
      map.keyboard.enable();

    }

    $("#addModal").on("shown.bs.modal", function () {
      map.resize();
    });
    L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      maxZoom: 18,
    }).addTo(map);







    // Event Handlers
    map.on("dragend", () => {
      this.$emit("mapDragend", map.getCenter());
    });
    map.on('zoomend', () => {
      this.$emit("mapDragend", map.getCenter());
      console.log("center :", map.getCenter())
    });
    map.on('load', () => {
      map.addLayer({
        'paint': {
          'fill-color': '#088',
          'fill-opacity': 0.8
        }
      });
    })
  },
};
</script>
