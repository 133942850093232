import { createWebHistory, createRouter } from "vue-router";

import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo) => {
  console.log("step 20, ", routeTo);
  // console.log('step 21 :', JSON.parse(localStorage['user'])?.roles.find(role => role.role.code == 'marketing_team_member').role.code);
  if (routeTo.path == "/login" && localStorage["user"]) {
    router.push("/dashboard/home");
    return;
  } else if (routeTo.path == "/login" && !localStorage["user"]) {
    console.log("login page");
  } else {
    const publicPages = ["/login", "/register", "/forgot-password"];
    const authpage = !publicPages.includes(routeTo.path);
    const loggeduser = localStorage.user;

    console.log(loggeduser);
    console.log("authpage", authpage);
    if (authpage && !loggeduser) {
      router.push("/login");
      // next();
    } else {
      console.log("ok");
      const decoded_user_data = JSON.parse(loggeduser);
      const user_roles = decoded_user_data?.roles.map((item) => item.role.code);
      const page_roles = routeTo.meta.auth_roles;
      let auth_status = false;
      for (let i = 0; i < user_roles?.length; i++) {
        console.log("role :", user_roles[i]);
        console.log("page roles :", page_roles);
        if (page_roles?.includes(user_roles[i])) {
          auth_status = true;
          break;
        } else {
          auth_status = false;
        }
      }
      if (auth_status) {
        // next();
        console.log("auth complete");
      } else {
        router.push("/dashboard/home");
        console.log("not allowed");
      }
    }
  }
});

// router.beforeEach(async (routeTo, _, next) => {
//   // setup data
//   // if (!store?.state?.authfack?.slaughter_process_id) {
//   //   console.log("log from get slaughter_process_id");
//   //   const response = await axios.get(
//   //     "slaughter-manufactoring-processes/slaughter"
//   //   );
//   //   const slaughter_process = response?.data;
//   //   store?.commit(
//   //     "authfack/setSlaughterProcessId",
//   //     slaughter_process?.data?.id
//   //   );
//   // }
//   // if (store?.state?.authfack?.allCompanies?.length == 0) {
//   //   const res = await axios.get("companies/");
//   //   store?.commit("authfack/setAllCompanies", res?.data?.data);
//   //   if (store?.state?.authfack?.companies?.length == 0) {
//   //     store.commit(
//   //       "authfack/setCompanies",
//   //       res?.data?.data?.map((i) => i.name)
//   //     );
//   //   }
//   // }
//   if (
//     (routeTo?.path == "/login" || routeTo?.path == "/home") &&
//     localStorage?.["kassala_farm_token"] &&
//     localStorage?.currentUser
//   ) {
//     console.log({ user: JSON.parse(localStorage?.currentUser) });
//     if (JSON.parse(localStorage?.currentUser)?.isTeamMember)
//       return router.push("/dashboard/team_member_home");
//   }
//   if (routeTo.path == "/login" && localStorage?.["kassala_farm_token"])
//     return router.push("/dashboard/home");
//   const publicPages = ["/login", "/register", "/forgot-password"];
//   const authpage = !publicPages.includes(routeTo.path);
//   const loggeduser = localStorage.kassala_farm_token;
//   if (authpage && !loggeduser) {
//     // localStorage.removeItem("permissions");
//     // localStorage.removeItem("companies");
//     localStorage.removeItem("currentUser");
//     router.push("/dashboard/login");
//   } else {
//     // const calcPermissions = (arr1, arr2) => {
//     //   const array = arr1.filter((i) => arr2.includes(i));
//     //   return array.length > 0;
//     // };
//     if (routeTo?.meta?.can_see && routeTo?.path != "/login") {
//       // store.commit("authfack/setPermissionsFromLocalStorage");
//       // let selected;
//       // if (store?.state?.authfack?.selected_company) {
//       //   selected = store?.state?.authfack?.selected_company;
//       // } else {
//       //   selected = Object.keys(store?.state?.authfack?.permissions).filter(
//       //     (o) => Object.keys(store?.state?.authfack?.permissions[o]).length > 0
//       //   )[0];
//       // }
//       // store.commit("authfack/setSelectedCompany", selected);
//       // const isAuthorized = calcPermissions(
//       //   Object.keys(routeTo?.meta?.can_see?.[selected]),
//       //   Object.keys(store?.state?.authfack?.permissions?.[selected])
//       // );
//       if (!isAuthorized) router.replace("/home");
//       next();
//     } else {
//       next();
//     }
//   }
// });
export default router;
