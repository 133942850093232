<script>
import appConfig from "@/app.config";

import store from "@/state/store"
var state = store;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

  },
  data() {
    return {

      PosUsers: [],

      page: 1,
      limit: 20,
      tot_pages: 0,
      PosUsersSearchMode: false,
      searchModel: "",
      state: state,

    };
  },
  props: {
    pos_station: Object
  },
  methods: {




    search() {
      this.PosUsersSearchMode = true;
      this.tot_pages = 0;
      this.http.post("pos-users/search", {
        search: this.searchModel,
        limit: this.limit,
        page: this.page,
        pos_station_id: this.$route.params.id,
        pos_hub_id: "",
        col: "name",
      }, null, true)
        .then((res) => {
          this.PosUsers = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.PosUsersSearchMode = false;
      this.get(this.page);
    },




    get(page) {
      console.log(page);
      this.http.post("pos-users/paginate", {
        limit: this.limit,
        page: page,
        pos_station_id: this.$route.params.id
      }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.PosUsers = res.data;
        });
    },
    editPass(options) {
      var data = {
        title: "userList.resetPass",
        inputs: [


          {
            label: "tables.userList.newPass",
            type: "password",
            model: "pass",
          },


        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              console.log("OK Clicked", obj);


              this.http.put(`pos-users`, options.id, obj).then((res) => {
                if (res.status) {

                  console.log(res.data);
                  this.popup.alert({ title: this.$t("messages.success"), msg: this.$t('messages.password_reset') })
                  this.get(this.page);

                }

              });


            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
  },
  created() {
    this.get(1);
  },
  mounted() {
  },
};
</script>

<template>
  <div class="row mt-3">
    <div class="col-6 mb-3">
      <div class="search-box chat-search-box w-50">
        <div class="position-relative">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button class="btn-close text-light" v-if="PosUsersSearchMode" @click="cancelappsearchMode()"></button>

    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr class="text-light text-center" style="background-color: #2a3042 !important">
        <th scope="col">
          <!----><!---->
          {{ $t("tables.userList.id") }}
          <!---->
        </th>
        <th scope="col">
          <!----><!---->
          {{ $t("tables.userList.name") }}
          <!---->
        </th>

        <th scope="col">
          <!----><!---->
          {{ $t("tables.userList.email") }}
          <!---->
        </th>


        <th scope="col">
          <!----><!---->
          {{ $t("tables.userList.phone") }}

          <!---->
        </th>

        <th scope="col">
          <!----><!---->
          {{ $t("tables.userList.role") }}

          <!---->
        </th>

        <th scope="col">
          <!----><!---->
          {{ $t("tables.userList.created") }}

          <!---->
        </th>

        <th scope="col">
          <!----><!---->
          {{ $t("tables.userList.action") }}

          <!---->
        </th>

      </tr>
    </thead>
    <tbody>
      <tr v-for="(user, index) in PosUsers" :key="user" class="text-center">
        <td>{{ index + 1 }} </td>
        <td> {{ user?.name }} </td>
        <td>{{ user?.email }} </td>

        <td>{{ user?.tel }} </td>
        <td style="max-width: 300px; white-space: normal;">
          {{ user?.roles.map((role) => {
            return (role.name_en.charAt(0).toUpperCase() +
              role.name_en.slice(1))
          }).join(', ') }}
        </td>
        <td>

          {{ new Date(user.created).toLocaleString("en-GB") }}
        </td>




        <td class=" text-start d-flex justify-content-center" style="  min-width: 280px;">


          <a name="" id="" class="btn btn-primary" @click.prevent="editPass(user)" href="#" role="button">{{
            $t("tables.userList.resetPass") }}</a>

        </td>

      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div>
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>


  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
