<template>
  <div :dir="$i18n.locale == 'ar' ? 'ltr' : 'ltr'" class="row">
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table
                class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
              <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
              >
                <th scope="col">#</th>
                <!-- <th scope="col">{{ $t("fixed_assets.module") }}</th> -->
                <th scope="col">{{ $t("fixed_assets.category") }}</th>
                <th scope="col">
                  {{ $t("popups.code") }}
                </th>
                <th scope="col">
                  {{ $t("fixed_assets.add_asset") }}
                </th>
                <th scope="col">
                  {{ $t("fixed_assets.ac_code") }}
                </th>
                <th scope="col">
                  {{ $t("fixed_assets.ac_asset") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(account, index) in accounts" :key="account">
                <td>{{ index + 1 }}</td>
                <td>
                  {{
                    $i18n.locale == "ar" ? account.name : account.name_en
                  }}
                </td>
                <td>
                  <div>
                    {{ account?.level_three_code }}
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <AutoComplete
                        v-model="account.search"
                        :suggestions="matchedAccounts"
                        @complete="searchAccount($event.query,'level_three')"
                        :optionLabel="showLabel"
                        style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                    />
                    <button
                        style="margin-inline-start: 8px"
                        @click="editAssetTypeAccount(account,'level_three')"
                        type="button"
                        class="btn btn-primary"
                    >
                      {{ $t("popups.edit") }}
                    </button>
                  </div>
                </td>
                <td>
                  <div v-if="account?.depreciable">
                    {{ account?.ad_level_three_code }}
                  </div>
                  <div v-else>-</div>
                </td>
                <td>
                  <div class="d-flex" v-if="account?.depreciable">
                    <AutoComplete
                        v-model="account.ad_search"
                        :suggestions="matchedAccounts"
                        @complete="searchAccount($event.query,'ad_level_three')"
                        :optionLabel="showLabel"
                        style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                    />
                    <button
                        style="margin-inline-start: 8px"
                        @click="editAssetTypeAccount(account,'ad_level_three')"
                        type="button"
                        class="btn btn-primary"
                    >
                      {{ $t("popups.edit") }}
                    </button>
                  </div>
                  <div v-else>-</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end  table -->

    <!--end-->
    <!--   Apps  Pagination     -->
    <div>
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page - 1)"
              aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
            class="page-item"
            :class="{ active: p == page }"
            v-for="p in typesTotalPages"
            :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
              p
            }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == typesTotalPages }">
          <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page + 1)"
              aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  props:["pos_type_id"],
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  watch:{
    pos_type_id(){
      console.log("WWWWW")
      this.get()
    }
  },
  data() {
    return {
      accounts: [],
      matchedAccounts: [],
    };
  },
  components: {

  },
  methods: {
    get() {
      this.http.post("default-pos-subledgers/asset-types",{
          pos_type_id:this.pos_type_id
      }).then((res) => {
        this.accounts = res.data;
      });
    },
    searchAccount(search,type) {
      this.http
          .post("default-pos-subledgers/accounts-setup/search-asset-account", {
            search,
            pos_type_id:this.pos_type_id,
            type,
          })
          .then((res) => {
            if (res.status) {
              this.matchedAccounts = res.data
            }
          });
    },
    editAssetTypeAccount(account,type) {
      console.log("account",account,type)
      this.http
          .post("default-pos-subledgers/types/setup/edit-asset-type", {
            id:account.id,
            level_three_id:account[(type=='level_three'?'search':'ad_search')].id,
            type
          })
          .then((res) => {
            if (res.status) {
              account.search = "";
              this.get();
            }
          });
    },
    showLabel(one) {
      return this.$i18n.locale == "ar" ? one.code + '-' + one.name : one.code + '-' + one.name_en;
    }
  },
  computed: {

  },
  mounted () {
    console.log("this.pos_type_id",this.pos_type_id)
    this.get(1);
  },
};
</script>
