
export default [
  {
    path: "pos-hub-packages",
    name: "pos-hub-packages",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () => import("../../views/PosHubSubscriptionsManagement/SubscriptionsManagement/packages.vue")
  }

]