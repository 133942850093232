<template>
  <div>
    <form>
      <div class="row mt-2">
        <div class="col">

          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="">{{ $t('pos_stations.table.price_including_tax') }} </label>

          </div>
          <span class="status-icon" v-if="posStation?.price_including_tax">
            <img class="" src="/assets/images/icons/check.svg" alt="img" />
          </span>
          <span class="" v-else>
            <img src="/assets/images/icons/cross.svg" alt="img" />
          </span>
          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="">{{ $t('pos_stations.table.has_full_accounting') }} </label>

          </div>
          <span class="status-icon" v-if="posStation?.has_full_accounting">
            <img class="" src="/assets/images/icons/check.svg" alt="img" />
          </span>
          <span class="" v-else>
            <img src="/assets/images/icons/cross.svg" alt="img" />
          </span>

          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="">{{ $t('pos_stations.table.pos_revenue_categorization') }} </label>

          </div>
          <p v-if="posStation && posStation?.pos_revenue_categorization" class="fw-bold">{{
              $i18n.locale == 'ar' ? posStation?.pos_revenue_categorization.name :
                posStation?.pos_revenue_categorization.name_en }}</p>
          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="">{{ $t('pos_stations.table.category_has_image') }} </label>

          </div>
          <span class="status-icon" v-if="posStation?.category_has_image">
            <img class="" src="/assets/images/icons/check.svg" alt="img" />
          </span>
          <span class="" v-else>
            <img src="/assets/images/icons/cross.svg" alt="img" />
          </span>



        </div>
        <div class="col">
          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="">{{ $t('pos_stations.table.single_products_list') }} </label>

          </div>
          <span class="status-icon" v-if="posStation?.single_products_list">
            <img class="" src="/assets/images/icons/check.svg" alt="img" />
          </span>
          <span class="" v-else>
            <img src="/assets/images/icons/cross.svg" alt="img" />
          </span>

          <template v-if="posStation && posStation.pos_type && posStation?.pos_type.name == 'Restaurant'">


            <div class="my-2 mt-4 d-flex justify-content-between">
              <label for="">{{ $t('pos_stations.table.waiters_accounting') }} </label>

            </div>
            <span class="status-icon" v-if="posStation?.waiters_accounting">
              <img class="" src="/assets/images/icons/check.svg" alt="img" />
            </span>
            <span class="" v-else>
              <img src="/assets/images/icons/cross.svg" alt="img" />
            </span>
            <div class="my-2 mt-4 d-flex justify-content-between">
              <label for="">{{ $t('pos_stations.table.has_floors') }} </label>

            </div>
            <span class="status-icon" v-if="!posStation?.single_floor">
              <img class="" src="/assets/images/icons/check.svg" alt="img" />
            </span>
            <span class="" v-else>
              <img src="/assets/images/icons/cross.svg" alt="img" />
            </span>

          </template>



        </div>
      </div>
    </form>
  </div>
</template>

<script>




export default {
  components: {
  },
  data() {
    return {
      componentKey: 0,
      posStation: {},
      user: {},
      posStationTypes: [],



    };
  },

  methods: {







    getPosStation() {
      this.http.get("pos-stations/" + this.$route.params.id, null, null, true).then((res) => {
        this.posStation = res.data;
        console.log("pos station :", this.posStation)
      });
    },
  },
  async created() {
    console.log("state :", this.state);


    this.getPosStation();
  },
};
</script>
<style scoped>
span.status-icon {
  width: 30px;
  display: block;
}

span.status-icon img {
  width: 100%;
}
</style>
