
export default [
  {
    path: "subscription-features",
    name: "subscription-features",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () =>
      import("../../views/PosSubscriptionsManagement/subscriptionFeatures/subscriptionFeatures.vue"),
  },
];
