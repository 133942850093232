<template>
  <router-view></router-view>
</template>

<script>
import { socket } from "@/services/socket";
export default {
  name: "App",
  components: {},
  async created() {



  },
  mounted() {
    if (localStorage["user"]) {

      socket.connect()
    }
  },
};
</script>

<style lang="scss">
#sidebar-menu {

  ul {

    a {
      &.router-link-exact-active {
        color: #d6c678;
      }
    }

    li {
      ul.sub-menu {
        li {

          a {

            &.router-link-exact-active {
              color: #ffffff !important;
            }
          }
        }
      }
    }
  }
}
</style>
