<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      accounts: [],
      l2accounts: [],
      levelTwoAccounts: [],
      levelThreeAccounts: [],
      matchedAccounts:[],
      pos_type_id:1,
      limit: 20,
      page: 1,
    };
  },
  methods: {
    getLevelTwo(page) {
      this.http
          .post("default-pos-subledgers/get-level-two-setup", {
            page,
            pos_type_id:this.pos_type_id,
            subledger_id: 8,
            limit: this.limit,
          })
          .then((res) => {
            if (res.status) {
              this.page = page;
              this.tot_pages = Math.ceil(res.tot / this.limit);
              this.l2accounts = res.data;
            }
          });
    },
    searchAccount(account,search) {
      console.log("account",account,"search",search)

      this.http
          .post("default-pos-subledgers/accounts-setup/search", {
            search,
            level:account.level,
            pos_type_id:this.pos_type_id,
            type:account.type,
          })
          .then((res) => {
            if (res.status) {
              this.matchedAccounts = res.data
            }
          });
    },

    editLevelTwo(account) {
      console.log("account",account)
      this.http.post("default-pos-subledgers/types/setup/edit-level-two", {
        id:account.id,
        level_two_id:account.search.id
      }).then((res) => {
        if (res.status) {
          this.l2accounts = [];
          this.getLevelTwo(this.page);
        }
      });
    },
    editLevelThree(account) {
      console.log("account",account)
      this.http
          .post("default-pos-subledgers/types/setup/edit-level-three", {
            id:account.sub_accounts[0].sas_id,
            level_three_id:account.search.id
          })
          .then((res) => {
            if (res.status) {
              console.log("done");
              account.search = "";
              this.getLevelThree(this.page);
            }
          });
    },
    getLevelThree() {
      this.http
          .post(
              "default-pos-subledgers/accounts-setup/pagination",
              {
                limit: this.limit,
                page: 1,
                subledger_id: 8,
                pos_type_id: this.pos_type_id,
              }
          )
          .then((res) => {
            if (res.status) {
              this.levelThreeAccounts = res.data.filter(account=>account.type);
            }
          });
    },
    showLabel(one) {
      return this.$i18n.locale == "ar" ? one.code + '-' + one.name : one.code + '-' + one.name_en; // Display the group label in AutoComplete
    },
  },
  computed: {

  },
  mounted() {
    this.getLevelTwo(1);
    this.getLevelThree();
  },
};
</script>

<template>
  <PageHeader :title="$t('accounts_setup.setup') + '  -  '+ $t('menu.menuitems.pos_accounting.subItems.sales_subledger')" />
  <div class="w-25 mb-1 mb-sm-3">
    <label class="form-label">{{$t('accounts_setup.type')}}</label>
    <select class="form-select" @change="getLevelThree(1);getLevelTwo(1)" v-model="pos_type_id">
      <option value="1">{{$t('accounts_setup.store')}}</option>
      <option value="2">{{$t('accounts_setup.restaurant')}}</option>
      <option value="3">{{$t('accounts_setup.pharmacy')}}</option>
    </select>
  </div>
  <b-tabs
      content-class="p-4"
      class="pt-2"
      nav-wrapper-class="nav-item"
      nav-class="nav-tabs-custom"
  >
    <b-tab active>
      <template v-slot:title>
        <div type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
          <span class="d-none d-sm-inline-block"
          >{{ $t("fixed_assets_subledger.level_two") }}
            </span>
        </div>
      </template>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table
                    class="table table-centered table-nowrap table-striped table-hover align-middle"
                >
                  <thead>
                  <tr
                      class="text-light"
                      style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <!-- <th scope="col">{{ $t("fixed_assets.module") }}</th> -->
                    <th scope="col">{{ $t("fixed_assets.module_name") }}</th>
                    <th scope="col">
                      {{ $t("popups.code") }}
                    </th>
                    <th scope="col">
                      {{ $t("fixed_assets.account_name") }}
                    </th>
                    <th scope="col">
                      {{ $t("fixed_assets.operations") }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(account, index) in l2accounts" :key="account">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar" ? account.name : account.name_en
                      }}
                    </td>
                    <td>{{ account?.code }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                            ? account?.l2_name
                            : account?.l2_name_en
                      }}
                    </td>
                    <td>
                      <div class="d-flex">
                        <AutoComplete
                            v-model="account.search"
                            :suggestions="matchedAccounts"
                            @complete="searchAccount(account,$event.query)"
                            :optionLabel="showLabel"
                            style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                        <button
                            style="margin-inline-start: 8px"
                            @click="editLevelTwo(account)"
                            type="button"
                            class="btn btn-primary"
                        >
                          {{ $t("popups.edit") }}
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- end  table -->
              <!-- Button trigger modal -->

              <!--   Apps  Pagination     -->
              <ul
                  class="pagination pagination-rounded justify-content-center mb-2"
              >
                <li class="page-item" :class="{ disabled: page == 1 }">
                  <a
                      class="page-link"
                      href="javascript: void(0);"
                      @click="getLevelTwo(page - 1)"
                      aria-label="Previous"
                  >
                    <i class="mdi mdi-chevron-left"></i>
                  </a>
                </li>
                <li
                    class="page-item"
                    :class="{ active: p == page }"
                    v-for="p in tot_pages"
                    :key="p"
                >
                  <a
                      class="page-link"
                      href="javascript: void(0);"
                      @click="getLevelTwo(p)"
                  >{{ p }}</a
                  >
                </li>

                <li class="page-item" :class="{ disabled: page == tot_pages }">
                  <a
                      class="page-link"
                      href="javascript: void(0);"
                      @click="getLevelTwo(page + 1)"
                      aria-label="Next"
                  >
                    <i class="mdi mdi-chevron-right"></i>
                  </a>
                </li>
              </ul>
              <!--end-->
            </div>
          </div>
        </div>
      </div>
    </b-tab>
    <b-tab >
      <template v-slot:title>
        <div type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
          <span class="d-none d-sm-inline-block"
          >{{ $t("fixed_assets_subledger.level_three") }}
            </span>
        </div>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table
                    class="table table-centered table-nowrap table-striped table-hover align-middle"
                >
                  <thead>
                  <tr
                      class="text-light"
                      style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <!-- <th scope="col">{{ $t("fixed_assets.module") }}</th> -->
                    <th scope="col">{{ $t("fixed_assets.module_name") }}</th>
                    <th scope="col">
                      {{ $t("popups.code") }}
                    </th>
                    <th scope="col">
                      {{ $t("fixed_assets.account_name") }}
                    </th>
                    <th scope="col">
                      {{ $t("fixed_assets.operations") }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(account,index) in levelThreeAccounts" :key="account">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar" ? account?.account_name : account?.account_name_en
                      }}
                    </td>
                    <td>{{ account?.sub_accounts[0]?.code }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                            ? account?.sub_accounts[0]?.l3_name
                            : account?.sub_accounts[0]?.l3_name_en
                      }}
                    </td>
                    <td>
                      <div class="d-flex">
                        <AutoComplete
                            v-model="account.search"
                            :suggestions="matchedAccounts"
                            @complete="searchAccount(account,$event.query)"
                            :optionLabel="showLabel"
                            style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                        <button
                            style="margin-inline-start: 8px"
                            @click="editLevelThree(account)"
                            type="button"
                            class="btn btn-primary"
                        >
                          {{ $t("popups.edit") }}
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- end  table -->
              <!-- Button trigger modal -->

              <!--   Apps  Pagination     -->
              <ul
                  class="pagination pagination-rounded justify-content-center mb-2"
              >
                <li class="page-item" :class="{ disabled: page == 1 }">
                  <a
                      class="page-link"
                      href="javascript: void(0);"
                      @click="getLevelThree(page - 1)"
                      aria-label="Previous"
                  >
                    <i class="mdi mdi-chevron-left"></i>
                  </a>
                </li>
                <li
                    class="page-item"
                    :class="{ active: p == page }"
                    v-for="p in tot_pages"
                    :key="p"
                >
                  <a
                      class="page-link"
                      href="javascript: void(0);"
                      @click="getLevelThree(p)"
                  >{{ p }}</a
                  >
                </li>

                <li class="page-item" :class="{ disabled: page == tot_pages }">
                  <a
                      class="page-link"
                      href="javascript: void(0);"
                      @click="getLevelThree(page + 1)"
                      aria-label="Next"
                  >
                    <i class="mdi mdi-chevron-right"></i>
                  </a>
                </li>
              </ul>
              <!--end-->
            </div>
          </div>
        </div>
      </div>
    </b-tab>
  </b-tabs>

</template>
