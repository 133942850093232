import posStationProfile from "../../../views/Users/profile/posStationsTab/posStationProfile/profile/index.vue"

export default [
  {
    path: "pos-station/:id",
    name: "posStation",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: posStationProfile,
  },
];
