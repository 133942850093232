import { reactive } from "vue";
import { io } from "socket.io-client";
import store from "@/state/store";
var vuex = store;
export const state = reactive({
  connected: false,
  fooEvents: [],
  barEvents: [],
  
});




export const socket = io(/* "http://localhost:5000" */ vuex.state.serverUrl , {
  autoConnect: false,
  query: {
    type:"pos_platform"
   
  },
});

socket.on("connect", () => {
  state.connected = true;
});

socket.on("disconnect", () => {
  state.connected = false;
});

socket.on("foo", (...args) => {
  state.fooEvents.push(args);
});

socket.on("bar", (...args) => {
  state.barEvents.push(args);
});
