export default [
  {
    path: "team_member_home",
    name: "team_member_home",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager", "marketing_team_member"],
    },
    component: () => import("../views/Marketing/MemberDashboard.vue"),
  },
  {
    path: "team_member_campaigns",
    name: "team_member_campaign",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager", "marketing_team_member"],
    },
    component: () =>
      import("../views/Marketing/CampaignTeamMember/MemberCampaigns.vue"),
  },
  {
    path: "team_member_campaigns/:id",
    name: "teamMemberCampaignDetails",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager", "marketing_team_member"],
    },
    component: () =>
      import("../views/Marketing/CampaignTeamMember/MemberCampaignDetails.vue"),
  },
  {
    path: "team_member_surveys",
    name: "team_member_surveys",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager", "marketing_team_member"],
    },
    component: () => import("../views/Marketing/MemberSurveys.vue"),
  },
  {
    path: "team_member_surveys/:id",
    name: "team_member_surveys_details",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager", "marketing_team_member"],
    },
    component: () => import("../views/Marketing/MemberSurveyDetails.vue"),
  },
  {
    path: "marketing_campaign_management",
    name: "marketing_campaign_management",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager"],
    },
    component: () => import("../views/Marketing/MarketingCampaignsPage.vue"),
  },
  {
    path: "marketing_campaign_management/:id",
    name: "marketing_campaign_management_details",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager"],
    },
    component: () =>
      import(
        "../views/Marketing/MarketingCampaignDetails/MarketingCampaignPage.vue"
      ),
  },
  {
    path: "marketing_campaign_management/:id/marketing_campaign_channels/:channelId",
    name: "marketingCampaignChannels",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager"],
    },
    component: () =>
      import(
        "../views/Marketing/MarketingCampaignDetails/ChannelDetails/ChannelDetailsPage.vue"
      ),
  },
  {
    path: "marketing_campaign_management/:id/marketing_campaign_channels/:channelId/marketing_campaign_channels_events/:eventId",
    name: "marketingCampaignChannelsEvents",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager"],
    },
    component: () =>
      import(
        "../views/Marketing/MarketingCampaignDetails/ChannelDetails/ChannelEventsDetailsPage.vue"
      ),
  },
  {
    path: "marketing_area_management",
    name: "marketing_area_management",
    meta: {
      authRequired: true,
      // can_see: marketing_manager,
      auth_roles: ["admin", "marketing_manager"],
    },
    component: () => import("../views/Marketing/MarketingAreaPage.vue"),
  },
  {
    path: "marketing_team_management",
    name: "marketing_team_management",
    meta: {
      authRequired: true,
      // can_see: marketing_manager,
      auth_roles: ["admin", "marketing_manager"],
    },
    component: () => import("../views/Marketing/MarketingTeamPage.vue"),
  },
  {
    path: "marketing_channels_management",
    name: "marketing_channels_management",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager"],
    },
    component: () => import("../views/Marketing/MarketingChannelsPage.vue"),
  },
  {
    path: "marketing_survey",
    name: "marketing_survey",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager"],
    },
    component: () => import("../views/Marketing/MarketingSurveyPage.vue"),
  },
  {
    path: "marketing_survey/:id",
    name: "marketing_survey_details",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager"],
    },
    component: () =>
      import(
        "../views/Marketing/MarketingSurveyDetails/MarketingSurveyPage.vue"
      ),
  },
  {
    path: "marketing_survey/:surveyId/survey-area/:areaId",
    name: "surveyArea",
    component: () =>
      import(
        "../views/Marketing/MarketingSurveyDetails/MarketingSurveyAreasTeamPage.vue"
      ),
    meta: {
      authRequired: true,
      auth_roles: ["admin", "marketing_manager"],
    },
  },
];
