export default [
  {
    path: "technical_support/:topic_id?",
    name: "technical_support",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () => import("../../views/TechnicalSupport/index.vue"),
  },

];
