<script>
import appConfig from "@/app.config";

import store from "@/state/store"
var state = store;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

  },
  data() {
    return {

      PosCategories: [],

      page: 1,
      limit: 20,
      tot_pages: 0,
      PosCategoriesSearchMode: false,
      searchModel: "",
      state: state,

    };
  },
  methods: {




    search() {
      this.PosCategoriesSearchMode = true;
      this.tot_pages = 0;
      this.http.post("pos-categories/search", {
        search: this.searchModel,
        limit: this.limit,
        page: this.page,
        pos_station_id: this.$route.params.id,
        pos_hub_id: "",
        col: "name",
      }, null, true)
        .then((res) => {
          this.PosCategories = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.PosCategoriesSearchMode = false;
      this.get(this.page);
    },




    get(page) {
      console.log(page);
      this.http.post("pos-categories/paginate", {
        limit: this.limit,
        page: page,
        pos_station_id: this.$route.params.id
      }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.PosCategories = res.data;
        });
    },
  },
  created() {
    this.get(1);
  },
  mounted() {
  },
};
</script>

<template>
  <div class="row mt-3">
    <div class="col-6 mb-3">
      <div class="search-box chat-search-box w-50">
        <div class="position-relative">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button class="btn-close text-light" v-if="PosCategoriesSearchMode" @click="cancelappsearchMode()"></button>

    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr class="text-light text-center" style="background-color: #2a3042 !important">
        <th scope="col">No</th>
        <th scope="col">{{ $t("categories.name") }}</th>

        <th scope="col">{{ $t("categories.img") }}</th>


      </tr>
    </thead>
    <tbody>
      <tr v-for="(app, index) in PosCategories" :key="app" class="text-center">
        <td>{{ index + 1 }}</td>
        <td>{{ app?.name }}</td>
        <td style=" min-width: 100px;">
          <img style="width: 64px; height: 64px" :src="app.img ? `${$store.state.mediaUrl}/${app?.img?.split('public/')[1]
            }` : `../../../../../../../../assets/images/default_image.png`" alt="" />
        </td>

      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages == 20">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>


  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
