export default [
  {
    path: "home",
    name: "home",

    component: () => import("../views/charts/apex.vue"),
    meta: {
      auth_roles: ["admin", "marketing_team_member", 'hr_manager', 'accountant_manager', 'purchase_manager', 'marketing_manager'],
    },
  },
];
