<template>
  <!-- Main Wrapper -->

  <PageHeader :title="$t('pos_stations.profile.title')" />
  <div>
    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
      <b-tab active>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block tab-text">{{ $t('pos_stations.profile.general_info') }}</span>
        </template>
        <GeneralInfo />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block tab-text">{{ $t('pos_stations.profile.setups') }}</span>
        </template>
        <Setups />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block tab-text">{{ $t('pos_stations.profile.categories') }}
          </span>
        </template>
        <Categories />
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block tab-text">{{ $t('pos_stations.profile.products') }}
          </span>
        </template>
        <Products v-if="pos_station && pos_station.id" :pos_station="pos_station" />
      </b-tab>




      <b-tab>
        <template v-slot:title>
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block tab-text">{{ $t('pos_stations.profile.users') }} </span>
        </template>
        <Users v-if="pos_station && pos_station.id" :pos_station="pos_station" />
      </b-tab>
      
    </b-tabs>
  </div>
</template>
<script>
import PageHeader from "@/components/page-header";

import GeneralInfo from "./General_Info/index.vue";
import Setups from './Setups/index.vue'
import Categories from "./Categories_Tab/index.vue";
import Products from "./Products/index.vue"
import Users from './Users/index.vue'


export default {
  data() {
    return {
      title: "Pos Station Profile",
      title1: "Full details pos station",
      pos_station: {}
    };
  },
  methods: {
    getPosStation() {
      this.http.get("pos-stations/" + this.$route.params.id, null, null, true).then((res) => {
        this.pos_station = res.data;
        console.log('pos station :', this.pos_station)
      });
    },
  },
  mounted() {


  },
  created() {
    this.getPosStation()

  },
  components: { GeneralInfo, Setups, Categories, Products, Users, PageHeader },
};
</script>

<style scoped>
.tab-text {
  color: #212b36 !important;
}
</style>
