<script>
import appConfig from "@/app.config";
import { Modal } from "bootstrap";
import store from "@/state/store"
var state = store;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

  },
  props: {
    pos_station: Object
  },
  data() {
    return {

      PosProducts: [],

      page: 1,
      limit: 20,
      tot_pages: 0,
      PosProductsSearchMode: false,
      // //////////pos product additions data////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      search_pos_product_addition: "",
      product_additions_modal: {},
      pos_product_additions: [],
      pos_product_additions_page: 1,
      pos_product_additions_limit: 50,
      pos_product_additions_total: 0,
      current_product_in_pos_product_additions: {},
      ///////////////////  end /////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////


      ///////////////pos product units data//////////////////////
      ///////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////
      productUnitsModal: '',
      units: [{ can_be_purchased: false, can_be_sold: false }],
      product_id: null,
      //////////////////end///////////////////////////////
      ///////////////////////////////////////////////////
      ///////////////////////////////////////////////////
      searchModel: "",
      state: state,

    };
  },
  methods: {
    // ///////////////////// pos product additions Method /////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    openProductAdditionModal(product) {
      this.current_product_in_pos_product_additions = product
      this.http.post('pos-product-additions/paginate', {
        page: this.pos_product_additions_page,
        limit: this.pos_product_additions_limit,
        pos_product_id: product.client_id,
        pos_category_id: product.pos_category_id,
        pos_station_id: this.$route.params.id

      }, null, true).then((res) => {

        if (res.status) {
          this.product_additions_modal.show()
          this.pos_product_additions = res.data
        }

      })
    },
    getPosProductAddition(product) {
      this.http.post('pos-product-additions/paginate', {
        page: this.pos_product_additions_page,
        limit: this.pos_product_additions_limit,
        pos_product_id: product.id

      }).then((res) => {

        if (res.status) {
          this.pos_product_additions = res.data
          this.pos_product_additions_total = res.tot
        }

      })
    },
    /////////////////////// end /////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////


    // ///////////////////// pos product units Method /////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    openEditUnitModal(record, product_id) {

      console.log("step 536 :", record)
      this.units = []
      this.product_id = null
      this.product_id = product_id
      this.units = JSON.parse(JSON.stringify(record));
      this.units.forEach((unit, index) => {
        this.units[index].can_be_purchased = this.units[index].can_be_purchased ? true : false
        this.units[index].can_be_sold = this.units[index].can_be_sold ? true : false
      })
      console.log("step 540 :", this.units)
      this.units.sort((a, b) => {
        return a.id - b.id
      })

      this.productUnitsModal.show()


    },
    /////////////////////// end /////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////



    search() {
      this.PosProductsSearchMode = true;
      this.tot_pages = 0;
      this.http.post("pos-products/search", {
        search: this.searchModel,
        limit: this.limit,
        page: this.page,
        pos_station_id: this.$route.params.id,
        pos_hub_id: "",
        col: "name",
      }, null, true)
        .then((res) => {
          this.PosProducts = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.PosProductsSearchMode = false;
      this.get(this.page);
    },




    get(page) {
      console.log(page);
      this.http.post("pos-products/paginate", {
        limit: this.limit,
        page: page,
        pos_station_id: this.$route.params.id
      }, null, true)
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          console.log('tot :', this.tot_pages)
          this.PosProducts = res.data;
        });
    },
  },
  created() {
    this.get(1);
  },
  mounted() {
    console.log('pos station in product tab : ', this.pos_station)
    this.product_additions_modal = new Modal("#productAdditionModal")
    this.productUnitsModal = new Modal("#productUnitsModal")


  },
};
</script>

<template>
  <div class="row mt-3">
    <div class="col-6 mb-3">
      <div class="search-box chat-search-box w-50">
        <div class="position-relative">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
        </div>
      </div>
    </div>
    <div class="col-6" style="display: flex; justify-content: flex-end">
      <button class="btn-close text-light" v-if="PosProductsSearchMode" @click="cancelappsearchMode()"></button>

    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr class="text-light text-center" style="background-color: #2a3042 !important">
        <th scope="col">No</th>
        <th scope="col">{{ $t("products.name") }}</th>
        <th v-if="pos_station && pos_station?.pos_type.name == 'Restaurant'">

          {{ $t("products.kitchen") }}

        </th>
        <th scope="col" v-if="pos_station && pos_station?.pos_type.name != 'Restaurant'">

          {{ $t("products.barcode") }}

        </th>
        <th scope="col">

          {{ $t("products.category") }}

        </th>
        <th scope="col" v-if="pos_station && pos_station?.pos_type.name == 'Restaurant'">
          {{ $t("products.sales_price") }}
        </th>
        <th scope="col" v-if="pos_station && pos_station?.pos_type.name == 'Restaurant'">

          {{ $t("products.additions") }}

        </th>
        <th scope="col" v-else>

          {{ $t("products.units") }}

        </th>
        <th scope="col">
          {{ $t("products.image") }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(app, index) in PosProducts" :key="app" class="text-center">
        <td>{{ index + 1 }}</td>
        <td>{{ app?.name }}</td>
        <td v-if="pos_station && pos_station?.pos_type.name == 'Restaurant'">
          <!---->{{ app?.pos_kitchen?.name }}<!---->
        </td>
        <td v-if="pos_station && pos_station?.pos_type.name != 'Restaurant'">
          <!---->{{ app?.barcode
          }}<!---->
        </td>
        <td>
          <!---->{{ app?.pos_category_name
          }}<!---->
        </td>
        <td v-if="pos_station && pos_station?.pos_type.name == 'Restaurant'">
          <!---->{{ app?.pos_product_units[0]?.sale_price
          }}<!---->
        </td>

        <td v-if="pos_station && pos_station?.pos_type.name == 'Restaurant'">
          <a @click="openProductAdditionModal(app)" href="javascript:void(0);" class="mx-auto plus-icon">
            <img class="w-100" src="/assets/images/icons/plus-circle.svg" alt="">
          </a>
        </td>
        <td v-else>
          <a @click="openEditUnitModal(app.pos_product_units, app.id)" href="javascript:void(0);"
            class="mx-auto plus-icon">
            <img class="w-100" src="/assets/images/icons/plus-circle.svg" alt="">
          </a>
        </td>
        <td style=" min-width: 100px;">
          <img style="width: 64px; height: 64px" :src="app.img ? `${$store.state.mediaUrl}/${app?.img?.split('public/')[1]
            }` : '../../../../../../../../assets/images/default_image.png'" alt="" />
        </td>


      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div>
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>

  <!----------------------------- Addition Modal Body ------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <div class="modal fade" id="productAdditionModal" tabindex="-1" role="dialog" aria-labelledby="modalTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTitleId">
            {{ $t("products.product_additions") }}
          </h5>
        </div>
        <div class="modal-body">

          <div class="card">
            <div class="card-body">

              <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
                <thead>
                  <tr class="text-light text-center" style="background-color: #2a3042 !important">


                    <th scope="col">
                      <!----><!---->
                      {{
                        $t("tables.product_additions.no")
                      }}
                      <!---->
                    </th>
                    <th scope="col">
                      <!----><!---->
                      {{
                        $t("tables.product_additions.name")
                      }}
                      <!---->
                    </th>

                    <th scope="col">
                      <!----><!---->
                      {{
                        $t("tables.product_additions.price")
                      }}
                      <!---->
                    </th>


                    <th scope="col">
                      <!----><!---->
                      {{
                        $t("tables.product_additions.active")
                      }}

                      <!---->
                    </th>


                  </tr>
                </thead>
                <tbody>
                  <!---->
                  <tr class="text-center" v-for="(product_addition, index) in pos_product_additions" :key="index">


                    <td>
                      <!---->{{ index + 1
                      }}<!---->
                    </td>
                    <td>
                      <!---->{{ product_addition?.name
                      }}<!---->
                    </td>
                    <td>
                      <!---->{{ product_addition?.price
                      }}<!---->
                    </td>

                    <td v-if="product_addition.active">
                      <button class="btn btn-success"> {{
                        this.$t("popups.active") }}</button>

                    </td>
                    <td v-else>

                      <button class="btn btn-danger"> {{
                        this.$t("popups.not_active") }}</button>
                    </td>





                  </tr>

                  <!---->
                </tbody>
                <!---->
              </table>


            </div>
          </div>









        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            {{ $t('popups.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-------------------------------------------end------------------------------------------------------------>
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->
  <!--------------------------------------------------------------------------------------------------------->


  <!--  Product Units Modal -->
  <!-- Modal Body -->
  <div class="modal fade" id="productUnitsModal" tabindex="-1" role="dialog" aria-labelledby="modalTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTitleId">
            {{ $t("products.product_units") }}
          </h5>
        </div>
        <div class="modal-body">

          <div class="row my-2">
            <div class="col">





              <label for="" class="form-label">{{ $t("products.product_units") }}</label>


              <div class="unit-product form-control mb-3" v-for="(unit, index) in units" :key="index">
                <div class="mb-3">

                  <div class="row mb-3">

                    <div class="col-md-4 col-12">
                      <label for="" class="form-label">{{ $t("products.unit_name") }}</label>
                      <p class="fw-bold"> {{ unit.name }}</p>
                    </div>

                    <div class="col-md-4 col-12" v-if='index != 0'>
                      <label for="" class="form-label">{{ $t("products.qty_in_parent_unit") }}</label>
                      <p type="number" class="fw-bold" id="" aria-describedby="helpId" placeholder="">{{
                        unit.qty_in_parent_unit }}</p>
                    </div>


                  </div>
                  <div class="row">
                    <div class="col-12">

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" disabled type="checkbox" id="" v-model="unit.can_be_purchased" />
                        <label class="form-check-label" for="">{{ $t("products.can_be_purchased") }}</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" disabled type="checkbox" id="" value="option2"
                          v-model="unit.can_be_sold" />
                        <label class="form-check-label" for="">{{ $t("products.can_be_sold") }}</label>
                      </div>
                    </div>




                    <div class="col-md-6 col-12" v-if="unit.can_be_purchased">
                      <label for="" class="form-label"> {{ $t("products.purchase_price") }} </label>
                      <p class="fw-bold" id="">{{ unit.purchase_price }}</p>

                    </div>
                    <div class="col-md-6 col-12" v-if="unit.can_be_sold">
                      <label for="" class="form-label"> {{ $t("products.sales_price") }} </label>

                      <p class="fw-bold"> {{ unit.sale_price }}</p>
                    </div>
                  </div>

                </div>



              </div>






            </div>





          </div>


        </div>
        <div class="modal-footer">

        </div>
      </div>
    </div>
  </div>
  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

.plus-icon {
  display: block;
  width: 40px;
}
</style>
