
export default [
    {
        path: "pos_hubs",
        name: "pos_hubs",
        meta: {
            authRequired: true,
            auth_roles: ["admin"],
        },
        component: () => import("../../views/Users/profile/posHubsTab/index.vue")
    }

]