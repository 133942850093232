<template>
  <div>
    <form>
      <div class="row mt-2">
        <div class="col">
          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for=""> {{ $t('pos_stations.table.pos_type') }} </label>
          </div>
          <p class="fw-bold">
            {{ posStation?.pos_type?.name }}
          </p>

          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="inputEmail4">{{ $t('pos_stations.table.name') }} </label>

          </div>
          <p class="fw-bold">
            {{ posStation.name }}
          </p>
          <!-- <label for="inputPassword4" class="my-2 mt-4">Register Number</label>
          <input type="text" v-model="posStation.register_number" class="form-control" disabled id="inputPassword4"
            placeholder="Register Number" />
          <label for="inputPassword4" class="my-2 mt-4">Register Document</label>
          <div>
            <button class="btn btn-primary">
              <a style="color: white" :href="`${$store.state.mediaUrl}${posStation?.register_doc?.split('./uploads/')[1]
                }`" download>Download</a>
            </button>
          </div> -->

          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="inputEmail4">{{ $t('pos_stations.table.tax_percentage') }} </label>

          </div>
          <p class="fw-bold">
            {{ posStation.tax + '%' }}
          </p>
          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="inputEmail4">{{ $t('pos_stations.table.price_including_tax') }} </label>

          </div>
          <span class="status-icon" v-if="posStation?.price_including_tax">
            <img class="" src="/assets/images/icons/check.svg" alt="img" />
          </span>
          <span class="" v-else>
            <img src="/assets/images/icons/cross.svg" alt="img" />
          </span>

          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="inputEmail4">{{ $t('pos_stations.table.brand') }} </label>

          </div>
          <div style="height: 350px">
            <img style="height: 100%;"
              :src="posStation.img ? `${$store.state.mediaUrl}/${posStation?.img?.split('public/')[1]}` : '/assets/images/default_image.png'"
              alt="" />

          </div>
        </div>
        <div class="col">
          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for="inputEmail4">{{ $t("pos_stations.form.location") }}
            </label>

          </div>

          <p class="fw-bold">
            {{ posStation.loc }}
          </p>
          <div class="my-2 mt-4 d-flex justify-content-between">
            <label for=""> {{ $t("pos_stations.form.location") }} </label>


          </div>
          <div class="position-relative p-0">
            <img src="/assets/images/marker-icon-2x.png" class="map-marker" />
            <!-- <p class="map-title">موقع المتجر</p> -->
            <location-selector :options="{
              center: { lat: 24.779171, lng: 46.844955 },
              id: 'my-map',
              width: '100%',
              height: '580px',
              initialZoom: 40,

              mapOptions: {
                isCoordinateEnabled /* dragging: isCoordinateEnabled ? true : false , keyboard : false, scrollWheelZoom: false */,
              },
            }" @map-dragend="
              posStation.lat = $event.lat;
            posStation.lng = $event.lng;
            " :control="isCoordinateEnabled" :key="componentKey" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>



import LocationSelector from "@/components/LocationSelector";

export default {
  components: {
    LocationSelector,
  },
  data() {
    return {
      componentKey: 0,
      posStation: {},
      user: {},




      isCoordinateEnabled: false,
      isImageEnabled: false,
      isTypeEnabled: false,
      imageFile: {},
    };
  },

  methods: {




    getPosStation() {
      this.http.get("pos-stations/" + this.$route.params.id, null, null, true).then((res) => {
        this.posStation = res.data;
        console.log("pos station :", this.posStation)
      });
    },
  },
  async created() {
    console.log("state :", this.state);


    this.getPosStation();
  },
};
</script>
<style scoped>
.map-title {
  position: absolute;
  z-index: 9999;
  width: 70%;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  transform: translate(-15%, 10px);
  padding: 7px;
  border-radius: 400px;
  box-shadow: 0 4px 1px rgba(0, 0, 0, 0.63);
}

.map-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35px;
  transform: translate(-50%, -100%);
  z-index: 999;
}

.feather-edit,
.feather-check-square {
  cursor: pointer;
}

span.status-icon {
  width: 30px;
  display: block;
}

span.status-icon img {
  width: 100%;
}
</style>
