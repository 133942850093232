import DefaultChartOfAccounts from "../views/DefaultPosAccounting/DefaultChartOfAccounts";
import FixedAssetsSubledger from "../views/DefaultPosAccounting/FixedAssetsSubledger";
import EmployeesSubledger from "../views/DefaultPosAccounting/EmployeesSubledger";
import SuppliersSubledger from "../views/DefaultPosAccounting/SuppliersSubledger";
import CustomersSubledger from "../views/DefaultPosAccounting/CustomersSubledger";
import PurchasesSubledger from "../views/DefaultPosAccounting/PurchasesSubledger";
import InventorySubledger from "../views/DefaultPosAccounting/InventorySubledger";
import SalesSubledger from "../views/DefaultPosAccounting/SalesSubledger";
import EquitySubledger from "../views/DefaultPosAccounting/EquitySubledger";
import CashAndBanksSubledger from "../views/DefaultPosAccounting/CashAndBanksSubledger";

export default [
  {
    path: "default_chart_of_accounts",
    name: "default_chart_of_accounts",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: DefaultChartOfAccounts,
  },
  {
    path: "default_cash_and_banks_subledger",
    name: "default_cash_and_banks_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: CashAndBanksSubledger,
  },
  {
    path: "default_assets_subledger",
    name: "default_assets_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: FixedAssetsSubledger,
  },
  {
    path: "default_emps_subledger",
    name: "default_emps_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: EmployeesSubledger,
  },
  {
    path: "default_suppliers_subledger",
    name: "default_suppliers_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: SuppliersSubledger,
  },
  {
    path: "default_customers_subledger",
    name: "default_customers_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: CustomersSubledger,
  },
  {
    path: "default_purchases_subledger",
    name: "default_purchases_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: PurchasesSubledger,
  },
  {
    path: "default_inventory_subledger",
    name: "default_inventory_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: InventorySubledger,
  },
  {
    path: "default_sales_subledger",
    name: "default_sales_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: SalesSubledger,
  },
  {
    path: "default_equity_subledger",
    name: "default_equity_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: EquitySubledger,
  },
];
