// import { archiving_manager } from "../../components/permissions";

export default [
  {
    path: "archiving_categories_management",
    name: "archiving_categories_management",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "archiving_manager"],
    },
    component: () =>
      import("../views/Archiving/Categories/CategoriesManagementPage.vue"),
  },
  {
    path: "archiving_shelves",
    name: "archiving_shelves",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "archiving_manager"],
    },
    component: () => import("../views/Archiving/Categories/ShelvesPage.vue"),
  },
  {
    path: "archiving_shelves/:shelveId",
    name: "archiving_shelve_folders",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "archiving_manager"],
    },
    component: () => import("../views/Archiving/Categories/FoldersPage.vue"),
  },
  {
    path: "archiving_documents_management",
    name: "archiving_documents_management",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "archiving_manager"],
    },
    component: () => import("../views/Archiving/DocumentsManagementPage.vue"),
  },
];
