
export default [
  {
    path: "pos-hub-features",
    name: "pos-hub-features",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () =>
      import("../../views/PosHubSubscriptionsManagement/PosHubsubscriptionFeatures/posHubSubscriptionFeatures.vue"),
  },
];
