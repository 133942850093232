export default [
  {
    path: "departments",
    name: "departments",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/DepartmentsPage.vue"),
  },
  {
    path: "departments/profile/:id",
    name: "departments_profile",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/DepartmentsProfilePage.vue"),
  },
  {
    path: "hr_personal_records",
    name: "hr_personal_records",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/PersonalRecordsPage.vue"),
  },
  {
    path: "hr_personal_records/empolyee_profile/:id",
    name: "hr_personal_records_empolyee_profile",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/EmployeeProfile.vue"),
  },
  {
    path: "temp_workers",
    name: "temp_workers",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/TempWorkersPage.vue"),
  },
  {
    path: "attendance",
    name: "attendance",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/AttendancePage.vue"),
  },
  {
    path: "holidays",
    name: "holidays",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/HoliDaysPage.vue"),
  },
  {
    path: "leaves",
    name: "leaves",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/LeavesPage.vue"),
  },
  {
    path: "performance_management",
    name: "performance_management",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/PerformanceManagementPage.vue"),
  },
  {
    path: "performance_management/profile/:id",
    name: "performance_management_profile",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/PerformanceProfileManagementPage.vue"),
  },
  {
    path: "transfares",
    name: "transfares",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/TransfaresPage.vue"),
  },
  {
    path: "complains",
    name: "complains",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/ComplainsPage.vue"),
  },
  {
    path: "designation",
    name: "designation",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/Designation.vue"),
  },
  {
    path: "payroll",
    name: "payroll",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/PayrollPage.vue"),
  },
  {
    path: "payroll_reports",
    name: "payroll_reports",
    meta: {
      authRequired: true,
      auth_roles: ["admin", "hr_manager"],
    },
    component: () => import("../views/Hr/PayrollReportsPage.vue"),
  },
];
