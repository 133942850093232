
export default [
  {
    path: "pos-hub-subscriptions",
    name: "pos-hub-subscriptions",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () =>
      import(
        "../../views/PosHubSubscriptionsManagement/PosHubSubscription/PosHubSubscriptionsManagement.vue"
      ),
  },
  {
    path: "pos-hub-requests",
    name: "posHubRequests",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () =>
      import(
        "../../views/PosHubSubscriptionsManagement/requests/requests.vue"
      ),
  }
 
];
