export default [
    {
      path: "/login_member",
      name: "loginMember",
      meta: {
        authRequired: true,
        auth_roles: ["admin", "archiving_manager"],
      },
      // component: () =>
      //   import("../views/account/loginMemberTeam.vue"),
    },
]