
export default [
  {
    path: "pos-subscriptions",
    name: "pos-subscriptions",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () =>
      import(
        "../../views/PosSubscriptionsManagement/PosSubscription/PosSubscriptionsManagement.vue"
      ),
  },
  {
    path: "requests",
    name: "requests",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () =>
      import(
        "../../views/PosSubscriptionsManagement/requests/requests.vue"
      ),
  }
 
];
