export default [
  {
    path: "users_management",
    name: "users_management",
    sensitive: true,
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () => import("../views/Users/UsersManagementPage.vue"),
  },

  {
    path: "point_of_sale_managers",
    name: "point_of_sale_managers",
    sensitive: true,
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () => import("../views/Users/PointOfSaleManagers.vue"),
  },

  {
    path: "point_of_sale_managers/profile/:id",
    name: "posOfSaleManagerProfile",
    sensitive: true,
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () => import("../views/Users/profile/index.vue"),
  }
 
 
];
